<template>
  <v-tabs v-model="internalValue" v-bind="$attrs" v-on="$listeners"
    :class="['zg-tab-helper', showLine && 'zg-tab-line']" next-icon="mdi-chevron-right icons--text"
    prev-icon="mdi-chevron-left icons--text">
    <slot></slot>
  </v-tabs>
</template>

<script>
export default {
  props: {
    value: {},
    arrowMargins: {
      type: String,
      default: "auto",
    },
    showLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.zg-tab-helper > .v-item-group {
  background-color: transparent !important;
}

.zg-tab-line > .v-slide-group {
  border-bottom: 1px solid #ebebeb;
}

.zg-tab-helper > .v-item-group > .v-slide-group__next {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.zg-tab-helper > .v-item-group > .v-slide-group__next .v-icon {
  font-size: 40px !important;
}

.zg-tab-helper>.v-item-group>.v-slide-group__prev {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.zg-tab-helper>.v-item-group>.v-slide-group__prev .v-icon {
  font-size: 40px !important;
}
</style>